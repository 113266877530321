export default function isIndexedDBOpenable(): Promise<boolean> {
  return new Promise((resolve) => {
    try {
      let preExist: boolean = true;
      const DB_CHECK_NAME = 'idb-check';
      const request = window.indexedDB.open(DB_CHECK_NAME);

      request.onsuccess = () => {
        request.result.close();
        // delete database only when it doesn't pre-exist
        if (!preExist) {
          window.indexedDB.deleteDatabase(DB_CHECK_NAME);
        }
        resolve(true);
      };

      request.onupgradeneeded = () => {
        preExist = false;
      };

      request.onerror = (event) => {
        if (request.error) {
          // Prevent IndexedDB unhandle-rejection
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1331103#c3
          event.preventDefault();
          // eslint-disable-next-line no-console
          console.error('idb-check error:', request.error.message);
          resolve(false);
        }
      };
    } catch (error) {
      resolve(false);
    }
  });
}
