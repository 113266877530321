import { logger } from './logger';

/**
 * The Keys are the trackIds you add on DOM.
 *
 * @example
 * <a data-track-id="example" href="icook.tw">
 * // ...
 * {
 *  'example': (t) => { logger.log('link-event', { url: t.href }) }
 * }
 */
const trackHandlerMap: {
  [trackId: string]: ((targetElement: HTMLElement) => void) | undefined;
} = {
  'recipe-included': (element) => {
    logger.logEvent('recipe_included', {
      included_by: element.dataset.includedBy as 'menu_blog' | 'campaign'
    });
  },
  'vip-entry-homepage-intro': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'homepage',
      vip_entry_name: 'frontpage_vip',
      cta_text: element.innerText
    });
  },
  'vip-entry-noAd': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'recipe',
      vip_entry_name: 'noad_vip',
      cta_text: element.innerText
    });
  },
  'vip-entry-setting': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'others',
      vip_entry_name: 'setting_membership',
      cta_text: element.innerText
    });
  },
  'vip-entry-recipe': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'recipe',
      vip_entry_name: 'vip_recipe',
      cta_text: element.innerText
    });
  },
  'vip-entry-search': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'search',
      vip_entry_name: element.dataset.name!,
      cta_text: element.innerText
    });
  },
  'vip-entry-search-carousel-top': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'search',
      vip_entry_name: 'search_vip_sorting_content_top',
      cta_text: element.innerText
    });
  },
  'vip-entry-search-carousel-bottom': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'search',
      vip_entry_name: 'search_vip_sorting_content_bottom',
      cta_text: element.innerText
    });
  },
  'vip-entry-category-sorting': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'category',
      vip_entry_name: 'category_vip_sorting',
      cta_text: element.innerText
    });
  },
  'vip-entry-category-carousel-top': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'category',
      vip_entry_name: 'category_vip_sorting_content_top',
      cta_text: element.innerText
    });
  },
  'vip-entry-category-carousel-bottom': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'category',
      vip_entry_name: 'category_vip_sorting_content_bottom',
      cta_text: element.innerText
    });
  },
  'vip-entry-save-sorting': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'others',
      vip_entry_name: 'save_vip_sorting',
      cta_text: element.innerText
    });
  },
  'vip-entry-sorting': (element) => {
    let trackPosition = window.location.pathname.split('/')[1];

    if (trackPosition === 'categories') {
      trackPosition = 'category';
    }
    if (trackPosition === 'users') {
      trackPosition = 'others';
    }

    logger.logEvent('vip_entry_click', {
      vip_entry_page: trackPosition!,
      vip_entry_name: 'click_vip_sorting',
      cta_text: element.innerText
    });
  },
  'vip-entry-footer': (element) => {
    let trackPosition = window.location.pathname.split('/')[1];

    if (trackPosition === '') {
      trackPosition = 'homepage';
    }
    if (trackPosition === 'recipes') {
      trackPosition = 'recipe';
    }
    if (trackPosition === 'categories') {
      trackPosition = 'category';
    }
    if (trackPosition === 'users') {
      trackPosition = 'others';
    }

    logger.logEvent('vip_entry_click', {
      vip_entry_page: trackPosition!,
      vip_entry_name: 'footer_vip',
      cta_text: element.innerText
    });
  },
  'vip-entry-navbar': (element) => {
    let trackPosition = window.location.pathname.split('/')[1];

    switch (trackPosition) {
      case 'recipes':
        trackPosition = 'recipe';
        break;
      case 'categories':
        trackPosition = 'category';
        break;
      case 'users':
        trackPosition = 'others';
        break;
      default:
        trackPosition = 'homepage';
    }

    logger.logEvent('vip_entry_click', {
      vip_entry_page: trackPosition,
      vip_entry_name: 'navbar_vip',
      cta_text: element.innerText
    });
  },
  'vip-entry-calories': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'recipe',
      vip_entry_name: 'recipe_vip_calories',
      cta_text: element.innerText
    });
  },
  'vip-entry-save-search': (element) => {
    logger.logEvent('vip_entry_click', {
      vip_entry_page: 'others',
      vip_entry_name: 'save_search_vip',
      cta_text: element.innerText
    });
  },
  homepage: (element) => {
    const { position, title, type, module } = element.dataset;
    logger.logEvent('FPv2_click', {
      FPv2_module: module!,
      FPv2_storytype: type!,
      FPv2_position_in_module: position!,
      FPv2_content_in_module: title! || element.innerText
    });
  },
  'trialfeedback-list-lightbox': (element) => {
    logger.logEvent('lightbox_image_click', {
      name: 'trialfeedback-list',
      position: element.dataset.trackPosition || ''
    });
  },
  'trialfeedback-lightbox': (element) => {
    logger.logEvent('lightbox_image_click', {
      name: 'trialfeedback',
      position: element.dataset.trackPosition || ''
    });
  },
  'recipe-action': (element) => {
    const { position, type, positive } = element.dataset;
    if (positive === 'true' || position === 'normal') return;
    const savePayload = {
      position: position as
        | 'top'
        | 'after_tips'
        | 'fix_bottom_bar'
        | 'invitation'
    };

    const likePayload = {
      position: position as 'top' | 'after_tips' | 'fix_bottom_bar'
    };

    switch (type) {
      case 'favorite':
        logger.logEvent('c2_recipe_save', savePayload);
        break;
      case 'like':
        logger.logEvent('c2_recipe_like', likePayload);
        break;
      default:
        break;
    }
  },
  'recipe-collect-invitation': (element) => {
    const { clickArea } = element.dataset;
    logger.logEvent('c2_recipe_w_invitation', {
      click_area: clickArea as 'skip' | 'save_now'
    });
  },
  'recipe-ingredient': (element) => {
    const { name } = element.dataset;
    logger.logEvent('recipe_ingredient_click', {
      ingredient_name: name!
    });
  },
  'recipe-cover-lightbox': () => {
    logger.logEvent('lightbox_image_click', {
      name: 'recipe-cover',
      position: '0'
    });
  },
  'recipe-steps-lightbox': (element) => {
    logger.logEvent('lightbox_image_click', {
      name: 'recipe-step',
      position: element.dataset.trackPosition || ''
    });
  },
  'choice-recipe': (element) => {
    logger.logEvent('choice_recipe', {
      destination_recipe: element.dataset.recipeName!,
      list: element.dataset.list!,
      rank: element.dataset.rank
    });
  },
  'choice-category': (element) => {
    logger.logEvent('choice_category', {
      destination_category: element.dataset.categoryName!
    });
  },
  'list-children': (element) => {
    logger.logEvent('recipes_list_children', {
      destination_child: element.dataset.childName!
    });
  },
  'track-recipe-share': (element) => {
    logger.logEvent('c2_recipe_share', {
      share_via: element.dataset.shareVia!,
      content_id: window.ICOOK.content_id
    });
  },
  'track-dish-share': (element) => {
    const segments = new URL(window.location.href).pathname.split('/');

    logger.logEvent('c2_dish_share', {
      share_via: element.dataset.shareVia!,
      content_id: segments[segments.length - 1]
    });
  },
  'track-list-share': (element) => {
    logger.logEvent('c2_list_share', {
      share_via: element.dataset.shareVia!,
      creator: element.dataset.creator! === 'true',
      content_id: window.ICOOK.content_id
    });
  },
  'track-campaign-share': (element) => {
    logger.logEvent('c2_campaign_share', {
      share_via: element.dataset.shareVia!,
      content_id: window.ICOOK.content_id
    });
  },
  'track-search-submit': () => {
    const recipeSearchInput =
      document.querySelector<HTMLInputElement>('input[name="q"]');
    const ingredientSearchInput = document.querySelector<HTMLInputElement>(
      'input[name="ingredients"]'
    );
    if (recipeSearchInput && ingredientSearchInput) {
      logger.logEvent('c2_search_submit', {
        recipe_term: recipeSearchInput.value,
        ingredient_term: ingredientSearchInput.value
      });
    }
  },
  'track-second-search': (element) => {
    const { originalKeyword, keyword, userId, visitorId, platform } =
      element.dataset;

    logger.logEvent('c2_second_search', {
      original_keyword: originalKeyword!,
      keyword: keyword!,
      user_id: userId!,
      visitor_id: visitorId,
      platform: platform!
    });
  },
  'track-search-filter': (element) => {
    const {
      type,
      enabled,
      keyword,
      resultCount,
      userId,
      vip,
      order,
      sortingStep,
      cookMethod,
      kitchenAppliances,
      clickTrial
    } = element.dataset;

    logger.logEvent('c2_search_filter', {
      type: type!,
      enabled: enabled!,
      keyword: keyword!,
      resultCount: resultCount!,
      userId: userId!,
      vip: vip!,
      order: order!,
      sorting_step: sortingStep!,
      cook_method: cookMethod!,
      kitchen_appliances: kitchenAppliances!,
      click_trial: clickTrial!
    });
  },
  'userpage-edit-sections': (element) => {
    const { username, where } = element.dataset;
    logger.logEvent('c3_edit_sections', {
      username,
      where: where as 'introduction' | 'no_authors_pick' | 'no_public_save'
    });
  },
  'userpage-author-key-metrics': (element) => {
    const { where, username, authorId } = element.dataset;

    logger.logEvent('c3_author_key_metrics', {
      username,
      authorId,
      where: where as
        | 'recipes'
        | 'dishes'
        | 'follower'
        | 'following'
        | 'campaign'
    });
  },
  'userpage-profile-key-metrics': (element) => {
    const { where, username, authorId } = element.dataset;
    logger.logEvent('c3_profile_key_metrics', {
      username,
      authorId,
      where: where as
        | 'recipes'
        | 'dishes'
        | 'follower'
        | 'following'
        | 'campaign'
    });
  },
  'userpage-author': (element) => {
    logger.logEvent('v3_author', {
      entry_type: element.dataset.entryType as 'personal' | 'user_page',
      visitorId: element.dataset.visitorId,
      authorId: element.dataset.author
    });
  },
  'userpage-dashboard': (element) => {
    const { where, username } = element.dataset;
    logger.logEvent('c3_dashboard', {
      username,
      where: where as 'after_tips' | 'settings'
    });
  }
};

document.addEventListener('click', (e: Event) => {
  let targetElement = e.target as HTMLElement | null;
  let count = 0;
  while (targetElement && count < 50) {
    const { trackId } = targetElement.dataset;
    if (trackId) trackHandlerMap[trackId]?.(targetElement);
    targetElement = targetElement.parentElement;
    count += 1;
  }
});
