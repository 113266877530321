import React from 'react';
import ReactDOM from 'react-dom';
import callOnNearScreen from './callOnNearScreen';

// Keep this function with three params, so ReactOnRails knows it is a renderer.
// i.e: noHydrate.length === 3
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noHydrate = (_props, _railsContext, _domNodeId) => {};

export const lazyHydrate = (loader) => {
  return function (props, _railsContext, domNodeId) {
    loader().then((module) => {
      const component = module.default;
      const node = document.getElementById(domNodeId);
      ReactDOM.hydrate(React.createElement(component, props), node);
    });
  };
};

export const lazyHydrateNearScreen = (loader, option) => {
  return function (props, _railsContext, domNodeId) {
    const node = document.getElementById(domNodeId);
    callOnNearScreen(
      () => {
        loader().then((module) => {
          const component = module.default;
          ReactDOM.hydrate(React.createElement(component, props), node);
        });
      },
      node,
      option
    );
  };
};
