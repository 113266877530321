import Cookies from 'js-cookie';

export const clone = (data: {}) => JSON.parse(JSON.stringify(data));

export const currentTime = () => new Date().getTime();

export const deepEqual = (a: {}, b: {}) =>
  JSON.stringify(a) === JSON.stringify(b);

export const isEmptyObject = (object: {}) => !Object.keys(object).length;

export const getUserId = (): string => {
  const userId = Cookies.get('icook_user_id');
  return userId ? userId : '';
};

export const hasKey = (obj: any, key: string | number | symbol) =>
  Object.hasOwnProperty.call(obj, key);

export const hasKeys = (obj: any, keys: (string | number | symbol)[]) =>
  keys.reduce((acc, key) => {
    return acc && hasKey(obj, key);
  }, true);

export const arrayUtils = {
  times: (length = 1, value: any) => {
    const initializer = () => {
      if (value && typeof value === 'object') {
        return clone(value);
      }
      return value;
    };

    return Array.from({ length }, initializer);
  }
};

export const getAuthToken = () => Cookies.get('CSRF-TOKEN') || '';

export const isFacebookApp = () => {
  try {
    return /FBA[N|V]/g.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};

export const isMobile = () => {
  try {
    return /Mobi/.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};

export const isIOS = () => {
  try {
    return /iP(od|ad|hone)/i.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};

export const isClient = () => {
  return typeof window !== 'undefined' && !!window.document;
};

export const isHybrid = () => {
  try {
    return /icook/i.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};

export const paddingDate = (date: number) => `0${date}`.slice(-2);
