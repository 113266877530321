import {
  init,
  configureScope,
  captureException,
  Integrations as SentryIntegrations
} from '@sentry/browser';
import { Dedupe } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { Extras } from '@sentry/types';
import { ApiError, FetchError } from '../lib/error';
import isJSDebugMode from './isJSDebugMode';

const sentryDSN = process.env.SENTRY_DSN_F2E;
const commit = process.env.GIT_COMMIT;

const transactionId = Math.random().toString(36).substr(2, 9);
export const transactionIdHeader = { 'X-Transaction-ID': transactionId };

const regexOfInternalOrigins =
  /https?:\/\/(?:[^/]+\.)?icook\.(test|dev|tw|network).*/;

/**
 * Fix for `TypeError: Cannot convert undefined or null to object`
 * ref: https://github.com/getsentry/sentry-javascript/issues/3388
 */
const isAffectByIssue3388 =
  typeof window !== 'undefined' &&
  navigator.userAgent.includes('Chrome/74.0.3729');

if (sentryDSN && typeof window !== 'undefined') {
  init({
    debug: isJSDebugMode,
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new Dedupe(),
      new Integrations.BrowserTracing({
        tracingOrigins: [regexOfInternalOrigins]
      }),
      new SentryIntegrations.TryCatch({
        requestAnimationFrame: !isAffectByIssue3388
      })
    ],
    tracesSampleRate: 0.1,
    release: commit,
    allowUrls: [regexOfInternalOrigins],
    // Mostly copied from https://gist.github.com/impressiver/5092952
    ignoreErrors: [
      // Webpack loading issues
      'ChunkLoadError',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      "Can't find variable: _AutofillCallbackHandler",
      "Can't find variable: _pcmBridgeCallbackHandler",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Firebase analytics on IE-11 / Mobile IE-11
      'TransactionInactiveError: TransactionInactiveError',
      // Error occurred on closing Facebook Webview
      'SecurityError: Blocked a frame with origin "https://icook.tw" from accessing a cross-origin frame.',
      // Errors mostly comes from country which have NATIONAL FIREWALL that blocks ADs and google analytics
      'Failed to fetch',
      // This is the message from the polyfill of fetch. Ignore this as the same reason of `Failed to fetch`
      // ref: https://github.com/github/fetch/blob/e42f201b8b0af8b3f2615abe8161c8087f52f1b2/fetch.js#L529
      'Network request failed',
      // VM5 interstitial ad error
      /document\.getElementById\('google_ads_iframe_\/40828883\/iCook_Recipe_Interstitial_0'\)\.contentWindow/,
      // Pocket iOS webview error
      'pktAnnotationHighlighter',
      // User cancelled request in iOS browsers
      // ref: https://stackoverflow.com/a/60860369
      'TypeError: 已取消',
      'TypeError: cancelled',
      'TypeError: キャンセルしました',
      'TypeError: cancelado',
      'AbortError'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    beforeSend(event, hint) {
      const error = hint?.originalException;
      if (
        error &&
        typeof error !== 'string' &&
        error.message &&
        (error.message.includes('Loading CSS chunk') ||
          error.message.includes(
            'Blocked a restricted frame with origin "https://icook.tw"'
          ))
      )
        return null;

      // Set fingerprint to merge this issue
      // https://sentry.io/organizations/polydice/issues/2663570942
      if (event.message?.includes('videoElement.playing')) {
        event.fingerprint = ['videoElement.playing'];
      }

      return event;
    }
  });

  configureScope((scope) => {
    scope.setTag('transaction_id', transactionId);
  });

  type CurrentUser = {
    username: string | null;
    is_admin: boolean | null;
    vip: boolean | null;
  };

  const currentUserNode = document.querySelector(
    '[data-key="icook-current-user"]'
  );
  if (currentUserNode) {
    const LEFT = '<!--';
    const RIGHT = '-->';
    const currentUser: CurrentUser = JSON.parse(
      currentUserNode.innerHTML.slice(
        LEFT.length,
        currentUserNode.innerHTML.length - RIGHT.length
      )
    );

    configureScope((scope) => {
      scope.setTag('vip', JSON.stringify(currentUser.vip || false));
      scope.setTag(
        'ad-blocked',
        JSON.stringify(window.__iCook_adblocked || false)
      );
      if (currentUser.username) {
        scope.setUser({
          username: currentUser.username,
          is_admin: currentUser.is_admin
        });
      }
    });
  }
}

export function captureApiException({
  endpoint,
  statusCode,
  statusText,
  extras
}: {
  endpoint: string;
  statusCode: number;
  statusText: string;
  extras?: Extras;
}) {
  /**
   * The errors should be tracked are:
   * - 5xx: Fatal error
   * - 400: It's worth to inspect where invalid syntax from that causing Bad Request
   * - 409: Campaigns::ProductRatingsController
   * - 408, 413, 414, 416, 429, 431: Possible reasons of "Failed to fetch" error
   */
  if (
    statusCode < 500 &&
    ![400, 408, 409, 413, 414, 416, 429, 431].includes(statusCode)
  ) {
    return;
  }

  const error = new ApiError(endpoint);

  captureException(error, (scope) => {
    scope.setFingerprint([endpoint, statusCode.toString()]);
    scope.setExtra('statusCode', statusCode);
    scope.setExtra('statusText', statusText);
    if (extras) {
      scope.setExtras(extras);
    }
    return scope;
  });
}

export function captureFetchException({
  endpoint,
  extras
}: {
  endpoint: string;
  extras?: Extras;
}) {
  const error = new FetchError(endpoint);

  captureException(error, (scope) => {
    scope.setFingerprint([endpoint]);
    if (extras) {
      scope.setExtras(extras);
    }
    return scope;
  });
}
