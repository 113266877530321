const callOnNearScreen = (callback, node, option) => {
  if (!node) return;

  const OPTION = {
    rootMargin: '0px 0px 400px 0px'
  };

  const io = new window.IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      callback();
      io.disconnect();
    }
  }, option || OPTION);

  io.observe(node);
};

export default callOnNearScreen;
