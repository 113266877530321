class ApiError extends Error {
  /**
   * Error due to not-ok response
   *
   * The Promise returned from fetch() won't reject on HTTP error status even if the response is an HTTP 404 or 500.
   * Instead, it will resolve normally.
   *
   * https://github.com/github/fetch#caveats
   *
   * @param message
   */
  constructor(message?: string) {
    super(message);
    this.name = 'ApiError';
  }
}

class FetchError extends Error {
  /**
   * Error due to network exception so client could not get the response.
   *
   * The Promise returned from fetch will only reject on network failure or if anything prevented the request from completing.
   *
   * https://github.com/github/fetch#caveats
   *
   * @param message
   */
  constructor(message?: string) {
    super(`${message}: Network failure or the request is not completed`);
    this.name = 'FetchError';
  }
}

export { ApiError, FetchError };
